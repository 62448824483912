import React, {useEffect, useRef, useState} from 'react';
import {useConnect} from "../../../api/contracts";
import BigNumber from "bignumber.js"
import {showNum} from "../../../utils/bigNumberUtil";
import ethereum from "../../../imgs/ethereum.svg";
import FDTIcon from "../../../imgs/FDT-icon.png";
import USDTIcon from "../../../imgs/usdt.png";
import {formatAddress} from "../../../utils/publicJs";
import ConnectWallet from "../../../component/ConnectWallet/ConnectWallet";
import user3 from "../../../imgs/user3.png"
import download from "../../../imgs/download.png"
import {zeroAddress} from "viem";
import {ETHPriceDecimals, MaxUint256} from "../../../config/constants";
import FLMIcon from "../../../imgs/FLMIcon.png"
import {
    Button,
    message,
    AutoComplete,
    Select,
    Form,
    Pagination, Input, Modal
} from 'antd';
import {getContractByName, getContractByContract} from "../../../api/connectContract";
import {dealMethod, dealPayMethod, viewMethod} from "../../../utils/contractUtil"

import listIcon from "../../../imgs/list-icon.webp";
import develop from "../../../env";
import {useNavigate} from "react-router-dom";
import judgeStatus from "../../../utils/judgeStatus";
import {
    getDonateRecord,
    getAllRegisters,
    getRecommender,
    getAddressFromId,
    getAllInvites, getAllFlmRate
} from "../../../graph/donateFireOg";
import OGPoolStyle from "./OGPoolStyle";
import {ETHDecimals, FDTDecimals, USDTDecimals, FLMDecimals, ZeroAddress} from "../../../config/constants";
import search from "../../../imgs/search.png";
import {dealTime} from "../../../utils/timeUtil";
import coinInfo from "../../../config/coinInfo";
import addressMap from "../../../api/addressMap";
const USDTDecimal = 6

const OGPoolPublic = (props) => {
    let {state, dispatch} = useConnect();
    const [activeNav, setActiveNav] = useState(1)
    const [total, setTotal] = useState(0)
    const [myRecommender, setMyRecommender] = useState()
    const [myId, setMyId] = useState()
    const [recordNav, setRecordNav] = useState(1)
    const [pageCount, setPageCount] = useState(20)
    const [curPage, setCurPage] = useState(1)
    const [FDTBalance, setFDTBalance] = useState(0)
    const [totalDonate, setTotalDonate] = useState(0)
    const [exchangeAmount, setExchangeAmount] = useState(0)
    const [inputValue, setInputValue] = useState(0)
    const [curAddress, setCurAddress] = useState()

    const [isSecondAdmin, setIsSecondAdmin] = useState(false)
    const [isThreeAdmin, setIsThreeAdmin] = useState(false)
    const [isFourAdmin, setIsFourAdmin] = useState(false)
    const [isFiveAdmin, setIsFiveAdmin] = useState(false)

    const [usdtBalance, setUSDTBalance] = useState(0)

    const [fdtBalance, setFdtBalance] = useState(0)

    const [activeUsedAmount, setActiveUsedAmount] = useState(0)
    const [activateAccountUsedAmount, setActivateAccountUsedAmount] = useState(0)
    const [myRecords, seMyRecords] = useState([])
    const [isShowRegister, setIsShowRegister] = useState(false)
    const [salePrice, setSalePriceV] = useState(0.01)
    const [status, setStatus] = useState(0)
    const [isAdmin, setIsAdmin] = useState(false)
    const [myTeam, setMyTeamArr] = useState([])

    const [levelCountObj, setLevelCountObj] = useState({})
    const [rewardTotalETH, setRewardTotalETH] = useState()
    const [rewardTotalFLM, setRewardTotalFLM] = useState()
    const [myTeamRecord, setMyTeamRecord] = useState([])
    const [inviteRateArr, setInvArr] = useState([])
    const [inviteRecordArr, setInvRecord] = useState([])
    const [flmRateRecordArr, setFlmRateRecord] = useState([])
    const [rateRecordArr, setRateRecord] = useState([])

    const [myStatus, setMyStatus] = useState({})
    const [activeArr, setActiveArr] = useState([])
    const [ethPrice, setETHPrice] = useState()
    const [userBuyMax, setUserBuyMax] = useState(0)
    const [userTotalBuy, setUserTotalBuy] = useState(0)
    const [searchData, setSearchData] = useState()
    const [curLevel, setCurLevel] = useState('0')

    const [allowance, setAllowance] = useState(0)

    const history = useNavigate();
    const [form] = Form.useForm();


    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("OGFire", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }


    const handlePayDealMethod = async (name, params, value) => {
        let contractTemp = await getContractByName("OGFire", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealPayMethod(contractTemp, state.account, name, params, value)
    }
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("OGFire", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const handleViewMethodByOwner = async (name, params, account) => {
        let contractTemp = await getContractByName("OGFire", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, account, name, params)
    }
    const handleSearchChange = async (e) => {
        setSearchData(e.target.value);
    }
    const handleSearch = async () => {
        const res = await getAddressFromId(searchData)
        if (searchData == undefined) {
            const hide = message.loading('', 0);
            await getMyTeam(state.account)
            setTimeout(hide, 1000)
            setCurAddress(state.account)
            getAddressRecommender(state.account)
        }
        if (res.data && res.data.allRegisters[0]) {
            if (state.api.utils.isAddress(res.data.allRegisters[0]._user)) {
                const address = res.data.allRegisters[0]._user
                setCurAddress(address)
                getAddressRecommender(address)
                const hide = message.loading('', 0);
                await getMyTeam(address)
                setTimeout(hide, 1000)
            }

        }

    }
    const getAdmin = async () => {
        let res = await handleViewMethod("owner", [])
        if (state.account.toLowerCase() == res.toLowerCase()) {
            setIsAdmin(true)
        } else {
            setIsAdmin(false)
        }
    }
    const handleCoinViewMethod = async (name, coinName, params) => {
        let contractTemp = await getContractByName(coinName, state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }

    const Row = (item, index) => {
        return <div className="list-item " key={index}>
            <div className="col no">
                {item.no ? parseInt(item.no) + 1 : "-"}
            </div>
            <div className="col address">
                {item.addr && (
                    <a href={develop.ethScan + "address/" + item.addr} target="_blank">
                        {formatAddress(item.addr)}
                    </a>
                )}
            </div>
            <div className="col ">
                {showNum(item.ethAmount / 10 ** USDTDecimals)}
            </div>
            <div className="col">
                {showNum(BigNumber(item.usdtAmount / 10 ** USDTDecimals).toFixed(2))}
            </div>

            <div className="col price">
                {showNum(item.salePrice, 4)}
            </div>
            <div className="col ">
                {showNum(BigNumber(item.fdtAmount / 10 ** FDTDecimals).toFixed(2))}
            </div>


            <div className="col">
                {dealTime(item.blockTimestamp)}
            </div>

        </div>
    }

    const getBalanceOfFDT = async () => {
        let balance = await handleViewMethod("getBalanceOfFireOG", [])
        balance = parseInt(BigNumber(balance).dividedBy(10 ** FDTDecimals).toString())
        if (balance > 0) {
            setFDTBalance(balance.toString())
        }
    }

    const getTotalDonate = async () => {
        let res = await handleViewMethod("totalDonate", [])
        setTotalDonate(BigNumber(res).dividedBy(10 ** FDTDecimals).toString())
    }
    const getfdtAmount = async (value) => {
        if (value > 0 || value == 0) {
            setInputValue(value)
            let res = await handleViewMethod("CanExchangeFireOg", [BigNumber(value).multipliedBy(10 ** USDTDecimal).toFixed(0)])
            setExchangeAmount(BigNumber(res).dividedBy(10 ** FDTDecimals).toFixed(2).toString())
        }
    }

    const getETHPrice = async () => {
        let price = await handleViewMethod("getLatesPrice", [])
        setETHPrice(BigNumber(price).dividedBy(10 ** ETHPriceDecimals))
    }
    const getAllowance = async () => {
        let contractTemp = await getContractByName("USDT", state.api,)
        const res = await viewMethod(contractTemp, state.account, "allowance", [ state.account,addressMap["OGFire"].address])
        setAllowance(res)
    }
    const handleApprove =  async () => {
        let contractTemp = await getContractByName("USDT", state.api,)
        await dealMethod(contractTemp, state.account, "approve", [addressMap["OGFire"].address ,MaxUint256])
        getAllowance()
    }
    const exchangeFdt = async () => {
        if (inputValue > 0) {
            await handleDealMethod("donate", [(BigNumber(inputValue).multipliedBy(10 ** USDTDecimal)).toString()])
            getData()
        }
    }
    const handleRegister = async () => {
        let refAddr = ""
        if (myStatus.activeStatus && myRecommender) {
            refAddr = state.account
        } else {
            if (!state.api.utils.isAddress(form.getFieldValue().referralCode)) {
                return
            }
            refAddr = form.getFieldValue().referralCode
        }

        await handleDealMethod("register", [refAddr.toString()])
        await getMyStatus()
        getAddressRecommender()

        setTimeout(() => {
            setIsShowRegister(false)
            getAddressRecommender()
        }, 2000)
        setTimeout(() => {
            setIsShowRegister(false)
            getAddressRecommender()
        }, 5000)
    }


    const getIsAdmin = async () => {
        const isS = await handleViewMethod("checkAddrForAdminLevelTwo", [state.account])
        const isT = await handleViewMethod("checkAddrForAdminLevelThree", [state.account])
        const isF = await handleViewMethod("checkAddrForAdminLevelFour", [state.account])
        const isFive = await handleViewMethod("checkAddrForAdminLevelFive", [state.account])
        setIsSecondAdmin(isS)
        setIsThreeAdmin(isT)
        setIsFourAdmin(isF)
        setIsFiveAdmin(isFive)
    }
    const getSalePrice = async () => {
        let res = await handleViewMethod("salePrice", [])
        setSalePriceV(BigNumber(res).dividedBy(1000).toString())
    }

    const CoinBalance = async () => {
        let res2 = await handleCoinViewMethod("balanceOf", "USDT", [state.account])
        console.log(res2)
        setUSDTBalance(BigNumber(res2).dividedBy(10 ** USDTDecimal).toString())
        // let res2 = await handleCoinViewMethod("balanceOf", "FDTOG", [state.account])
        // setFdtBalance(BigNumber(res2).dividedBy(10 ** FDTDecimals).toString())
    }

    const getMyStatus = async () => {

        let activeStatus = await handleViewMethod("checkAddrForActivateAccount", [state.account])
        let registerStatus = await handleViewMethod("isNotRegister", [state.account])
        setMyStatus({
            registerStatus,
            activeStatus
        })
    }
    const getActivateAccount = async () => {
        const refRes = await getAllRegisters(state.account)
        try {
            let refArr = refRes.data.allRegisters
            let myActiveArr = []


            refArr.forEach(refItem => {
                if (refItem.recommenders.toLowerCase() == state.account) {
                    if (!myActiveArr.includes(refItem._user)) {
                        myActiveArr.push(refItem._user)

                    }
                }
            })
            setActiveArr(myActiveArr)
        } catch (e) {
            console.log(e)
        }
    }

    const getData = async () => {
        try {
            let judgeRes = await judgeStatus(state)
            if (!judgeRes) {
                return
            }
            getMyInviteCount()
            await getAddressRecommender(state.account)

            getAdmin()
            getIsAdmin()
            getTotalDonate()
            getSalePrice()
            getMyStatus()
            getBalanceOfFDT()
            CoinBalance()
            getUserBuyMax()
            getAllowance()
            await getInviteRate()
            await getInviteRecord()


            //getETHPrice()


        } catch (e) {

        }
    }
    const onChangePage = async (page) => {
        await setCurPage(page)
    }


    const handleShowSizeChange = async (page, count) => {
        setPageCount(count)
    }
    const getMyInviteCount = async () => {
        const used = await handleViewMethod("activeUsedAmount", [state.account])
        const total = await handleViewMethod("activateAccountUsedAmount", [])
        getActivateAccount()
        setActiveUsedAmount(used)
        setActivateAccountUsedAmount(total)
    }

    const getAddressRecommender = async (address) => {
        const res = await getRecommender(address)
        if (res && res.data && res.data.allRegisters[0]) {
            const resArr = res.data.allRegisters
            setMyRecommender(resArr[resArr.length - 1].recommenders)
            setMyId(resArr[resArr.length - 1].Contract_id)
        }
    }
    const getUserBuyMax = async () => {
        // const res = await handleViewMethod("userBuyMax", [])
        const buyNum = await handleViewMethod("userTotalBuy", [state.account])
        setUserTotalBuy(BigNumber(buyNum).dividedBy(10 ** USDTDecimal).toString())
        setUserBuyMax(10000)
    }
    const getRefArr = async (address, myTeamArr, level) => {
        let refRes = await getAllRegisters(address)
        if (refRes.data && refRes.data.allRegisters && refRes.data.allRegisters.length > 0) {
            let refArr = refRes.data.allRegisters

            const realRefArr = []
            refArr.forEach(item => {
                let hasAddress = false;
                realRefArr.forEach(refItem => {
                    if (refItem._user == item._user) {
                        hasAddress = true
                    }
                })
                if (!hasAddress) {
                    realRefArr.push(item)
                }
            })
            refArr = realRefArr

            if (refArr[0]._user != address) {
                refArr.forEach(item => {
                    item.level = level
                })
                myTeamArr.push(...refArr)
            }

            level++
            if (level > 5) {
                return
            }


            for (let i = 0; i < refArr.length; i++) {
                if (refArr[i]._user != address) {
                    await getRefArr(refArr[i]._user, myTeamArr, level)
                }
            }
        }
        return myTeamArr

    }
    const getLevelInviteRate = async (address, recordTime, owner) => {
        for (let i = 0; i < inviteRecordArr.length; i++) {
            const item = inviteRecordArr[i]
            const flmItem = flmRateRecordArr[i]
            if (item.addr == address && (item.blockTimestamp == recordTime)) {
                if (item.recommender1.toString().toLowerCase() == owner.toString().toLowerCase()) {
                    return {
                        rate: item.rate1,
                        flmRate: flmItem.flmRate4,
                    }
                }
                if (item.recommender2.toLowerCase() == owner.toLowerCase()) {
                    return {
                        rate: item.rate2,
                        flmRate: flmItem.flmRate3,
                    }
                }
                if (item.recommender3.toLowerCase() == owner.toLowerCase()) {
                    return {
                        rate: item.rate3,
                        flmRate: flmItem.flmRate2,
                    }
                }
                if (item.recommender4.toLowerCase() == owner.toLowerCase()) {
                    return {
                        rate: item.rate4,
                        flmRate: flmItem.flmRate1,
                    }
                }
                if (item.recommender5.toLowerCase() == owner.toLowerCase()) {
                    return {
                        rate: item.rate5,
                        flmRate: flmItem.flmRate0,
                    }
                }
            }
        }
        return 0

    }


    const getMyTeam = async (address) => {
        let res = await getDonateRecord()

        if (res.data && res.data.allRecords) {
            const myTeamArr = await getRefArr(address, [], 1)
            const allRecords = res.data.allRecords
            let myTeamRecord = [], myRecord = []
            for (let i = 0; i < allRecords.length; i++) {
                const record = allRecords[i]
                if (record.addr.toLowerCase() == address.toLowerCase()) {
                    myRecord.push(record)
                }
            }
            seMyRecords(myRecord)
            // count my team level number

            let levelRewardObj = {
                level1Total: 0,
                level2Total: 0,
                level3Total: 0,

                total: 0
            }
            // count my team level number
            for (let i = 0; i < myTeamArr.length; i++) {
                const item = myTeamArr[i]
                levelRewardObj.total++
                if (item.level == 1) {
                    levelRewardObj.level1Total++
                }
                if (item.level == 2) {
                    levelRewardObj.level2Total++
                }
                if (item.level == 3) {
                    levelRewardObj.level3Total++
                }


            }
            // operate reward and get record
            let totalETH = 0, totalFLM = 0

            for (let i = 0; i < myTeamArr.length; i++) {
                const item = myTeamArr[i]
                for (let j = 0; j < allRecords.length; j++) {
                    const record = allRecords[j]
                    if (item._user.toLowerCase() == record.addr.toLowerCase()) {
                        let rate = (await getLevelInviteRate(item._user, record.blockTimestamp, address)).rate
                        let flmRate = (await getLevelInviteRate(item._user, record.blockTimestamp, address)).flmRate
                        if (!rate) {
                            rate = 0
                        }
                        const ethIncome = BigNumber(record.ethAmount).multipliedBy(rate).dividedBy(100).dividedBy(10 ** USDTDecimals).toString()
                        const flmIncome = BigNumber(record.fdtAmount).multipliedBy(flmRate).dividedBy(100).dividedBy(10 ** FDTDecimals).toString()

                        if (item.ethIncome > 0) {
                            item.ethIncome = BigNumber(item.ethIncome).plus(ethIncome)
                            item.flmIncome = BigNumber(item.flmIncome).plus(flmIncome)
                        } else {
                            item.ethIncome = ethIncome
                            item.flmIncome = flmIncome
                        }

                        item.no = record.no

                        if ((curLevel == item.level) || (curLevel == 0)) {
                            totalETH = BigNumber(totalETH).plus(ethIncome)
                            totalFLM = BigNumber(totalFLM).plus(flmIncome)
                        }
                        if (item.level) {
                            record.level = item.level
                            record.rate = rate
                            record.flmRate = flmRate
                            myTeamRecord.push(record)
                        }
                    }


                }

            }
            // let myTeamIncome = []
            // myTeamArr.forEach(item => {
            //     if (item.ethIncome > 0) {
            //         myTeamIncome.push(item)
            //     }
            // })
            myTeamRecord.sort((a, b) => {
                return BigNumber(b.no).comparedTo(a.no)
            })
            myTeamRecord.sort((a, b) => {
                return BigNumber(b.no).comparedTo(a.no)
            })
            setMyTeamRecord(myTeamRecord)
            setMyTeamArr(myTeamArr)
            setLevelCountObj(levelRewardObj)
            setRewardTotalFLM(totalFLM.toString())
            setRewardTotalETH(totalETH.toString())
        }

    }
    const getInviteRate = async () => {
        // const rateLength = await handleViewMethod("getInviteRate", [])
        let tempArr = []
        for (let i = 0; i < 5; i++) {
            const inviteRate = await handleViewMethod("inviteRate", [i])
            tempArr.push(inviteRate.toString())
        }
        await setInvArr(tempArr)
    }
    const getInviteRecord = async () => {
        const res = await getAllInvites()
        if (res && res.data) {
            setInvRecord(res.data.allInvites)
        }
        const res2 = await getAllFlmRate()
        if (res2 && res2.data) {
            setFlmRateRecord(res2.data.allFlmRates)
            setRateRecord(res2.data.allTeamRates)
        }
    }

    useEffect(async () => {
        if (flmRateRecordArr.length == inviteRecordArr.length) {
            getMyTeam(state.account)
        }
    }, [inviteRateArr, inviteRecordArr, flmRateRecordArr]);
    useEffect(() => {
        if (flmRateRecordArr.length == inviteRecordArr.length && curAddress) {
            getMyTeam(curAddress)
        }
    }, [curLevel]);

    useEffect(() => {
        setCurAddress(state.account)
        setMyRecommender()

        getData()
        form.resetFields()
    }, [state.account]);


    useEffect(() => {
        if (state.account && state.apiState == "READY") {
            setStatus(1)
        } else {
            setStatus(0)
        }
    }, [state.account, state.networkId, state.apiState])

    const addToken = async () => {
        await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: coinInfo.FDTOG.type,
                options: {
                    address: coinInfo.FDTOG.address,
                    symbol: coinInfo.FDTOG.symbol,
                    image: coinInfo.FDTOG.image,
                    decimals: coinInfo.FDTOG.decimals,
                },
            },
        });
    }
    const coinOptions = [
        {
            label: "500",
            value: '500',
        },
        {
            label: "1000",
            value: '1000',
        },
        {
            label: "3000",
            value: '3000',
        },
        {
            label: "5000",
            value: '5000',
        },
        {
            label: "10000",
            value: '10000',
        },
    ];
    const levelSelect = [
        {
            label: "All",
            value: '0',
        },
        {
            label: "Level 1",
            value: '1',
        },
        {
            label: "Level 2",
            value: '2',
        },
        {
            label: "Level 3",
            value: '3',
        },
        {
            label: "Level 4",
            value: '4',
        },
        {
            label: "Level 5",
            value: '5',
        },
    ];
    return (
        <OGPoolStyle>
            <Modal className="signup-dialog" title="Sign up" open={isShowRegister} onOk={handleRegister}
                   footer={[
                       <Button className="add-btn" type="primary" onClick={() => {
                           handleRegister()
                       }}>Submit</Button>
                   ]}
                   onCancel={() => {
                       setIsShowRegister(false)
                   }}>
                <Form form={form} name="control-hooks" className="form">
                    <strong className="input-title">Wallet Address</strong>
                    <Form.Item
                        name="referralCode"
                        validateTrigger="onBlur"
                        validateFirst={true}
                        className="dialog-input"
                    >
                        <div className="temp-input">
                            {state.isMobile && <div className="value address">
                                {state.account ? state.account.substring(0, 20) + "..." + state.account.substring(28, 46) : ""}
                            </div>}
                            {!state.isMobile && <div className="value " style={{fontSize: "16px"}}>
                                {state.account}
                            </div>}
                        </div>
                    </Form.Item>
                    <strong className="input-title">Referral Code</strong>

                    {myStatus.activeStatus && myRecommender && <div className="input-content">
                        {myRecommender}
                    </div>}
                    {!(myStatus.activeStatus && myRecommender) && <div>
                        <Form.Item
                            name="referralCode"
                            validateTrigger="onBlur"
                            validateFirst={true}
                            className="dialog-input"
                        >
                            <Input style={{fontSize: "16px"}}/>
                        </Form.Item>
                    </div>}


                </Form>
            </Modal>
            <div className="page-title">
                OG Pool
                {isAdmin && <Button style={{
                    float: 'right',
                    background: '#373232',
                    margin: '0px 13px',
                    textAlign: 'center',
                    lineHeight: '28px',
                    width: "32px",
                    height: '32px',
                    border: '1px solid rgba(255, 255, 255, 0.15)',
                    borderRadius: '50%',
                }}
                                    onClick={() => {
                                        history("/OGPoolV5Admin")
                                    }}>
                    <img src={user3} style={{width: '22px', marginLeft: '-10px', marginTop: '-10px'}}/>
                </Button>}


            </div>
            <div className="header-nav">
                <div className="fire-nav-list ">
                    <div className={"nav-item " + (activeNav == 1 ? "active" : "")} onClick={() => {
                        setActiveNav(1)
                    }}>
                        OG Donate Pool
                    </div>
                    <div className={"nav-item " + (activeNav == 2 ? "active" : "")} onClick={() => {
                        setActiveNav(2)
                    }}>
                        Team
                    </div>
                    {myStatus.activeStatus &&
                        <div className={"nav-item " + (activeNav == 3 ? "active" : "")} onClick={() => {
                            setActiveNav(3)
                        }}>
                            Active Accounts
                        </div>}
                    {(isSecondAdmin | isThreeAdmin || isFourAdmin || isFiveAdmin) &&

                        <div className={"nav-item " + (activeNav == 4 ? "active" : "")} onClick={() => {
                            history("/OGV5UserAdmin")
                        }}>
                            Lv{isSecondAdmin ? 2 : ""}{isThreeAdmin ? "AM" : ""}{isFourAdmin ? "UM" : ""}{isFiveAdmin ? "GM" : ""} Admin
                        </div>

                    }
                </div>

            </div>
            {activeNav == 1 && (
                <div className="part1">
                    <div className="panel-box">
                        {state.isMobile && <div className="flex-box status-header">
                            <div className="status-info">
                                <div className="info-item">
                                    <div className={"dot" + (myStatus.registerStatus ? " active" : "")}></div>
                                    {!myStatus.registerStatus && < >Unregistered</>}
                                    {myStatus.registerStatus && <>Registered</>}
                                </div>
                                <div className="info-item" >
                                    <div className={"dot" + (myStatus.activeStatus ? " active" : "")}></div>
                                    {!myStatus.activeStatus && <>Non-activated</>}
                                    {myStatus.activeStatus && <>Activated</>}
                                </div>
                            </div>
                            {!myStatus.registerStatus && <div className="signUp-btn" onClick={() => {
                                setIsShowRegister(true)
                            }}>
                                Sign Up
                            </div>}
                            {myStatus.registerStatus && <div className="id-box">
                                ID: {myId}
                            </div>}
                        </div>}
                        <div className="panel-container">
                            {!state.isMobile && <div className="flex-box status-header">
                                <div className="status-info">
                                    <div className="info-item">
                                        <div className={"dot" + (myStatus.registerStatus ? " active" : "")}></div>
                                        {!myStatus.registerStatus && < >Unregistered</>}
                                        {myStatus.registerStatus && <>Registered</>}
                                    </div>
                                    <div className="info-item">
                                        <div className={"dot" + (myStatus.activeStatus ? " active" : "")}></div>
                                        {!myStatus.activeStatus && <>Non-activated</>}
                                        {myStatus.activeStatus && <>Activated</>}
                                    </div>
                                </div>
                                {!myStatus.registerStatus && <div className="signUp-btn" onClick={() => {
                                    setIsShowRegister(true)
                                }}>
                                    Sign Up
                                </div>}
                                {myStatus.registerStatus && <div className="id-box">
                                    ID: {myId}
                                </div>}
                            </div>}
                            {!state.isMobile && <div className="donate-info">
                                <div className="info-item">
                                    <div className="name">
                                        FireOG Pool Amount
                                    </div>
                                    <div className="value">
                                        {showNum(FDTBalance)}
                                    </div>
                                </div>
                                <div className="flex-box">
                                    <div className="info-item">
                                        <div className="name">
                                            OG Price
                                        </div>
                                        <div className="value">
                                            ${salePrice}
                                        </div>
                                    </div>
                                    <div className="info-item">
                                        <div className="name">
                                            FireOG Value
                                        </div>
                                        <div className="value">
                                            ${showNum(BigNumber(FDTBalance).multipliedBy(salePrice))}
                                        </div>
                                    </div>


                                </div>
                            </div>}
                            {state.isMobile && <div className="donate-info">
                                <div className="flex-box">
                                    <div className="name">
                                        FireOG Pool Amount
                                    </div>
                                    <div className="value">
                                        {showNum(FDTBalance)}
                                    </div>
                                </div>
                                <div className="flex-box">
                                    <div className="name">
                                        OG Price
                                    </div>
                                    <div className="value">
                                        ${salePrice}
                                    </div>


                                </div>
                                <div className="flex-box">
                                    <div className="name">
                                        FireOG Value
                                    </div>
                                    <div className="value">
                                        ${showNum(BigNumber(FDTBalance).multipliedBy(salePrice))}
                                    </div>
                                </div>
                            </div>}

                            <div className="donation-box">

                                <div className="title donate-header">
                                    Donate
                                    <div className="add-coin" onClick={addToken}>
                                        Add FireOG
                                    </div>
                                </div>
                                <Form form={form} name="control-hooks" className="form">
                                    <div className="donate-part">
                                        <div className="balance-box">

                                            <strong>Value: ${showNum(ethPrice * inputValue)} </strong>

                                            <div className="right flex-box">
                                                <div className="name">
                                                    Balance:
                                                </div>
                                                <div className="value">
                                                <span style={{marginRight: '6px'}}>
                                                     {showNum(usdtBalance, 3)}
                                                </span>
                                                    <span>USDT</span>
                                                </div>
                                            </div>
                                        </div>
                                        <Form.Item
                                            name="amount"
                                            validateTrigger="onBlur"
                                            validateFirst={true}
                                        >
                                            <div className="input-box">
                                                <Select
                                                    value={inputValue}
                                                    onChange={(e) => {
                                                        getfdtAmount(e)
                                                    }}
                                                    style={{width: 200}}
                                                    options={coinOptions}
                                                    placeholder="0"
                                                    filterOption={(inputValue, option) =>
                                                        option.value.indexOf(inputValue) !== -1 &&
                                                        /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/.test(inputValue)
                                                    }
                                                />
                                                <div className="right-tip">
                                                    <img className="coin-icon" src={USDTIcon} alt=""/>
                                                    USDT
                                                </div>
                                            </div>
                                        </Form.Item>
                                    </div>
                                    <img className="down-icon" src={download} alt=""/>


                                    <div className="donate-part" style={{marginTop: '8px'}}>
                                        <div className="balance-box">
                                            <strong className="">
                                                Your receive
                                            </strong>
                                            <div className="balance-box ">
                                                <div className="name">
                                                    Balance:
                                                </div>
                                                <div className="value">
                                                    <span style={{marginRight: '6px'}}>{showNum(fdtBalance)}</span>
                                                    <span> FireOG</span>
                                                </div>
                                            </div>

                                        </div>
                                        <Form.Item
                                            name="pid"
                                            validateTrigger="onBlur"
                                            validateFirst={true}

                                        >
                                            <div className="input-box">
                                                <div className="exchangeAmount">
                                                    {exchangeAmount}
                                                </div>
                                                <div className="right-tip">
                                                    <img className="coin-icon" width={20} src={FDTIcon} alt=""/>
                                                    FireOG
                                                </div>
                                            </div>
                                        </Form.Item>
                                    </div>
                                    {/*not Regist*/}
                                    {!myStatus.registerStatus &&

                                        <Button onClick={() => {
                                            setIsShowRegister(true)
                                        }} type="primary" className="donate">
                                            {!myStatus.registerStatus && <span>Sign Up</span>}
                                        </Button>

                                    }
                                    {/*registed*/}
                                    {myStatus.registerStatus && <div>
                                        {status == 0 && <ConnectWallet className="connect-button"/>}
                                        {
                                            status == 1 && !inputValue &&
                                            <Button type="primary" className="donate">
                                                Enter an amount
                                            </Button>
                                        }
                                        {
                                            status == 1 && BigNumber(usdtBalance).lt(inputValue) &&
                                            <Button type="primary" className="donate">
                                                Balance not enough
                                            </Button>
                                        }
                                        {
                                            status == 1 && inputValue > 0 && !BigNumber(usdtBalance).lt(inputValue) &&
                                            <>

                                            {!BigNumber(allowance).gt(inputValue*10**USDTDecimal) &&    <Button type="primary" className="donate" onClick={() => {
                                                handleApprove()
                                            }}>
                                                Approve
                                            </Button>}

                                                <Button type="primary" className="donate" onClick={() => {
                                                    exchangeFdt()
                                                }}>
                                                    Donate
                                                </Button>
                                            </>
                                        }

                                    </div>

                                    }


                                    <div className="tip">
                                        I have already donated {showNum(userTotalBuy)} USDT, I can donate up
                                        to {showNum(userBuyMax)} USDT, I can continue
                                        donating {showNum(BigNumber(userBuyMax).minus(userTotalBuy))} USDT.
                                        {/* 1FireOG = {salePrice} USD ，Donate up to 2ETH */}
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className="panel-box part2">
                        <div className="panel-container">
                            <div className="list-top-part">
                                <div className="panel-title">
                                    My Donate Records
                                </div>
                            </div>
                            <div className="fire-list-box mydonate-list" style={{minWidth: '100%'}}>
                                <div className="list-header ">
                                    <div className="col">
                                        No.
                                    </div>
                                    <div className="col">
                                        Wallet Address
                                    </div>
                                    <div className="col">
                                        USDT
                                    </div>
                                    <div className="col">
                                        Cost USDT
                                    </div>

                                    <div className="col">
                                        Price
                                    </div>
                                    <div className="col">
                                        FireOG
                                    </div>


                                    <div className="col">
                                        Time(UTC)
                                    </div>
                                </div>
                                {myRecords.map((item, index) => (
                                    index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                    Row(item, index)
                                ))}


                            </div>
                            <div className="pagination">
                                {
                                    recordNav == 1 && <Pagination current={curPage} showSizeChanger
                                                                  onShowSizeChange={handleShowSizeChange}
                                                                  onChange={onChangePage} total={total}
                                                                  defaultPageSize={pageCount}/>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            )}
            {activeNav == 2 && (
                <div className="team-part">
                    <div className="panel-box">
                        <div className="panel-container">

                            <div className="active-content-box">
                                <div className="content-item-box">
                                    <div className="name">
                                        Wallet Address
                                    </div>
                                    {state.isMobile && <div className="value" style={{width: "auto"}}>
                                        <div className="address">
                                            {formatAddress(curAddress)}
                                        </div>
                                    </div>}
                                    {!state.isMobile && <div className="value address" style={{width: "auto"}}>
                                        {curAddress}
                                    </div>}
                                </div>
                                {myRecommender && <div className="content-item-box">
                                    <div className="name">
                                        Recommender
                                    </div>
                                    {state.isMobile && <div className="value " style={{width: "auto"}}>
                                        <div className="address">
                                            {formatAddress(myRecommender)}
                                        </div>
                                    </div>}
                                    {!state.isMobile && <div className="value address" style={{width: "auto"}}>
                                        {myRecommender}
                                    </div>}
                                </div>}

                            </div>
                        </div>
                        {state.isMobile && <div className="panel-title " style={{marginTop: "20px"}}>
                            Team Income
                            <div className="team-reward">
                                <div className="reward-item">
                                    <div className="name">
                                        USDT Total Income
                                    </div>
                                    <div className="value">
                                        <img width={24} src={USDTIcon} alt=""/> {showNum(rewardTotalETH, 4)}
                                    </div>
                                </div>

                            </div>
                        </div>}
                        {state.isMobile && <div className="panel-container team-count-monbile">
                            <div className="in-line">
                                <strong>Total:</strong>
                                <span>{levelCountObj.total}</span>
                            </div>

                            <div className="in-line">
                                <strong>Level1:</strong>
                                <span>{levelCountObj.level1Total}</span>
                            </div>
                            <div className="in-line">
                                <strong>Level2:</strong>
                                <span>{levelCountObj.level2Total}</span>
                            </div>
                            <div className="in-line">
                                <strong>Level3:</strong>
                                <span>{levelCountObj.level3Total}</span>
                            </div>


                        </div>}

                        {state.isMobile && <div className="filter-box" style={{justifyContent: "space-between"}}>
                            <Select
                                value={curLevel}
                                onChange={(e) => {
                                    setCurLevel(e)
                                }}
                                style={{width: 200}}
                                options={levelSelect}
                            />
                            <form className="search-box">
                                <Input style={{borderRadius: '50px'}} allowClear value={searchData}
                                       onChange={handleSearchChange} placeholder="ID"/>
                                <Button className="search-btn"
                                        style={{width: '45px', borderRadius: '45px', height: '40px'}}
                                        onClick={handleSearch} type="primary">
                                    <img src={search} style={{width: '25px', margin: '0px -10px'}}/>
                                </Button>
                            </form>
                        </div>}
                        <div className="panel-container">


                            <div className="team-count">
                                {!state.isMobile && <div className="panel-title flex-title">
                                    Team Income
                                    <div className="right flex-box">
                                        <div className="reward-item">
                                            <img width={20} src={USDTIcon} alt=""/> {showNum(rewardTotalETH, 4)}
                                        </div>

                                    </div>
                                </div>}


                                {!state.isMobile && <div className="in-line">

                                    <div className="info-item">
                                        <strong>Total:</strong>
                                        <span>{levelCountObj.total}</span>
                                    </div>
                                    <div className="info-item">
                                        <strong>Level1:</strong>
                                        <span>{levelCountObj.level1Total}</span>
                                    </div>
                                    <div className="info-item">
                                        <strong>Level2:</strong>
                                        <span>{levelCountObj.level2Total}</span>
                                    </div>

                                    <div className="info-item">
                                        <strong>Level3:</strong>
                                        <span>{levelCountObj.level3Total}</span>
                                    </div>



                                </div>}

                                {!state.isMobile &&
                                    <div className="in-line filter-box" style={{justifyContent: "space-between"}}>
                                        <Select
                                            value={curLevel}
                                            onChange={(e) => {
                                                setCurLevel(e)
                                            }}
                                            style={{width: 200}}
                                            options={levelSelect}
                                        />
                                        <form className="search-box">
                                            <Input style={{borderRadius: '50px'}} allowClear value={searchData}
                                                   onChange={handleSearchChange} placeholder="ID"/>
                                            <Button className="search-btn"
                                                    style={{width: '45px', borderRadius: '45px', height: '40px'}}
                                                    onClick={handleSearch} type="primary">
                                                <img src={search} style={{width: '25px', margin: '0px -10px'}}/>
                                            </Button>
                                        </form>
                                    </div>}
                            </div>

                            <div className="fire-list-box team-list" style={{marginTop: "20px"}}>

                                <div className="list-header">
                                    <div className="col">
                                        Level
                                    </div>
                                    <div className="col">
                                        No.
                                    </div>
                                    <div className="col">
                                        ID
                                    </div>
                                    <div className="col">
                                        Address
                                    </div>
                                    <div className="col">
                                        USDT Income
                                    </div>


                                </div>
                                {
                                    myTeam.map((item, index) => (
                                        index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                        (curLevel == 0 || curLevel == item.level) &&
                                        <div className="list-item " key={index}>
                                            <div className="col">
                                                {item.level}
                                            </div>
                                            <div className="col no">
                                                {item.no ? parseInt(item.no) + 1 : "-"}
                                            </div>
                                            <div className="col id" style={{cursor: "pointer"}} onClick={() => {
                                                setSearchData(item.Contract_id);
                                                handleSearch()
                                            }}>
                                                {(item.Contract_id || item.Contract_id == 0) ? item.Contract_id : "--"}
                                            </div>
                                            <div className="col ">
                                                <div className="address">
                                                    <a target="_blank"
                                                       href={develop.ethScan + "/address/" + item._user}> {formatAddress(item._user)} </a>
                                                </div>

                                            </div>
                                            <div className="col flex-box">
                                                <img width={20} height={20} style={{marginRight: "3px"}} src={USDTIcon}
                                                     alt=""/>
                                                {showNum(item.ethIncome, 6)}
                                            </div>

                                        </div>
                                    ))
                                }


                            </div>

                        </div>
                        {state.isMobile && <div className="panel-title" style={{marginTop: "20px"}}>
                            Team Donate Records
                        </div>}
                        <div className="panel-container">
                            {!state.isMobile && <div className="panel-title">
                                Team Donate Records
                            </div>}
                            <div style={{marginTop: "20px"}} className="fire-list-box donate-list">
                                <div className="list-header ">
                                    <div className="col">
                                        No.
                                    </div>

                                    <div className="col">
                                        Level
                                    </div>
                                    <div className="col">
                                        Allocation
                                    </div>
                                    <div className="col">
                                        Address
                                    </div>
                                    <div className="col">
                                        USDT
                                    </div>
                                    <div className="col">
                                        FireOG
                                    </div>
                                    <div className="col">
                                        Og Price
                                    </div>
                                    <div className="col">
                                        Cost
                                    </div>
                                    <div className="col">
                                        Rewards
                                    </div>
                                    <div className="col">
                                        Time
                                    </div>
                                </div>
                                {
                                    myTeamRecord.map((item, index) => (
                                        index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                        <div className="list-item" key={index}>
                                            <div className="col no">
                                                {parseInt(item.no) + 1}
                                            </div>
                                            <div className="col">
                                                {item.level}
                                            </div>
                                            <div className="col">
                                                {item.rate > 0 &&
                                                    <div className="item">
                                                        ETH: {item.rate}%

                                                    </div>
                                                }
                                                {item.flmRate > 0 &&
                                                    <div className="item">
                                                        FLM: {item.flmRate}%
                                                    </div>
                                                }

                                            </div>
                                            <div className="col ">
                                                <div className="address">
                                                    <a target="_blank"
                                                       href={develop.ethScan + "/address/" + item.addr}> {formatAddress(item.addr)} </a>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <img width={20} height={20} style={{marginRight: "3px"}} src={USDTIcon}
                                                     alt=""/>
                                                {showNum(item.ethAmount / 10 ** USDTDecimals, 3)}
                                            </div>
                                            <div className="col">
                                                <img width={20} height={20} style={{marginRight: "3px"}} src={FDTIcon}
                                                     alt=""/>
                                                {showNum(item.fdtAmount / 10 ** FDTDecimals, 1)}
                                            </div>
                                            <div className="col price">
                                                <img width={20} height={20} style={{marginRight: "3px"}} src={USDTIcon}
                                                     alt=""/>
                                                {showNum(item.salePrice, 4)}
                                            </div>
                                            <div className="col cost">
                                                <img width={20} height={20} style={{marginRight: "3px"}} src={USDTIcon}
                                                     alt=""/>
                                                {showNum(item.usdtAmount / 10 ** USDTDecimals, 3)}
                                            </div>
                                            <div className="col  ">
                                                {item.rate > 0 && (
                                                    <div className="item flex-box">
                                                        <img width={22} height={22} style={{marginRight: "3px"}}
                                                             src={USDTIcon} alt=""/>
                                                        {showNum(BigNumber(item.ethAmount / 10 ** USDTDecimals).multipliedBy(item.rate / 100), 3)}
                                                    </div>
                                                )}


                                            </div>
                                            <div className="col">
                                                {dealTime(item.blockTimestamp)}
                                            </div>
                                        </div>
                                    ))
                                }


                            </div>
                            <div className="pagination">
                                {
                                    <Pagination current={curPage} showSizeChanger
                                                onShowSizeChange={handleShowSizeChange}
                                                onChange={onChangePage} total={total}
                                                defaultPageSize={pageCount}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {activeNav == 3 && (
                <div className="panel-box">
                    <div className="panel-container">
                        <div className="panel-title">
                            Active Accounts
                        </div>
                        <div className="active-content-box">
                            <div className="content-item-box flex-box" style={{justifyContent: "space-between"}}>
                                <div className="item">
                                    <div className="name">
                                        My Address
                                    </div>

                                    {state.isMobile && <div className="value address" style={{width: "auto"}}>
                                        {formatAddress(state.account)}
                                    </div>}
                                    {!state.isMobile && <div className="value address" style={{width: "auto"}}>
                                        {state.account}
                                    </div>}

                                </div>
                                <div className="item" style={{justifyContent: "space-between"}}>
                                    <div className="name">
                                        ID
                                    </div>
                                    <div className="my-id pid">
                                        {myId}
                                    </div>
                                </div>
                            </div>
                            <div className="content-item-box">

                                <div className="flex-box  content-list">
                                    <div className="content-item">
                                        <div className="name">
                                            Total times
                                        </div>
                                        <div className="value">
                                            {activateAccountUsedAmount}
                                        </div>
                                    </div>
                                    <div className="content-item">
                                        <div className="name">
                                            Used times
                                        </div>
                                        <div className="value">
                                            {activeUsedAmount}
                                        </div>
                                    </div>
                                    <div className="content-item">
                                        <div className="name">
                                            Available times
                                        </div>
                                        <div className="value">
                                            {BigNumber(activateAccountUsedAmount).minus(activeUsedAmount).toString()}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="fire-list-box admin3-list">
                            <div className="active-list-row list-header">
                                <div className="col">
                                    No.
                                </div>


                                <div className="col ">
                                    Address
                                </div>

                            </div>

                            {
                                activeArr.map((item, index) => (
                                    <div className="list-item active-list-row" key={index}>
                                        <div className="col no">
                                            {index + 1}
                                        </div>

                                        <div className="col address">
                                            <a href={develop.ethScan + "/address/" + item} target="_blank">{item}</a>
                                        </div>

                                    </div>)
                                )
                            }

                        </div>
                    </div>
                </div>
            )

            }
        </OGPoolStyle>
    )
}
export default OGPoolPublic
