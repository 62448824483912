import erc20Abi from "../abi/erc20.json";
import MintPassPort from "../abi/MintPassPort.json";

import PrivateExchangePoolOG from "../abi/PrivateExchangePoolOG.json";
import PrivateExchangePoolOGV5 from "../abi/PrivateExchangePoolOGV5.json"
import FDT from "../abi/fdt.json"

import erc1155 from "../abi/erc1155.json"
import rainbowNFT from "../abi/nft7.json"
import ogV7 from "../abi/ogV7.json"
import fireOgPool from "../abi/fireOgPool.json"
let CONTRACTS
CONTRACTS = {
    erc20: {address: "0x46B85F2E50BFB50F4F78d29e98E679a859d5F839", abi: erc20Abi},
    user: {address: "0x1853B8d43B9500F60cc68b672642A02eC26667B6", abi: MintPassPort},

    PrivateExchangePoolOG: {address: "0x65d741A9d21cA93AB8aC202b7e6693AE3656CEFa", abi: PrivateExchangePoolOG},
    // main
    // USDT: {address: "0xc91d4c3a82dace65a0aac9ecd004c95baebcd1cc",abi:erc20Abi},
    // PrivateExchangePoolOGV5: {address: "0xf955351c6Cb66008D2027b5717A168B93Cf57f09", abi: PrivateExchangePoolOGV5},
    // FDT: {address: "0x37F361E62c7AebD5C75614Dda1a18fb84f0b99A1", abi: FDT},

    // ogV7: {address: "0x454ccf823A38bc90Db4877A570ea8F864776A9e4", abi: ogV7},

    // // test
    // USDT: {address: "0xD034EAd76F2F904cC75AA6d3a5243a8ED8454bE3",abi:erc20Abi},
    // FDT: {address: "0xac044041115aa185c2dbdeac1bb9d49e5da52ef8", abi: FDT},
    // OGFire: {address: "0xFBB8B499e86894f983B98E98A586540673e46f81", abi: fireOgPool},
    // ogV7: {address: "0xFBB8B499e86894f983B98E98A586540673e46f81", abi: fireOgPool},
    // main
    USDT: {address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",abi:erc20Abi},
    FDT: {address: "0x79Cc513670759DA94bFBa306051E59b08562B193", abi: FDT},
    OGFire: {address: "0x2a8C5b2358dA9fDE89FFA800432A84De6ec787Bf", abi: fireOgPool},
    ogV7: {address: "0x2a8C5b2358dA9fDE89FFA800432A84De6ec787Bf", abi: fireOgPool},


    FDTOG: {address: "0x08E06d14086e3AE0293B7806CE6c32a2a21b316F", abi: erc20Abi},

    // ogV7: {address: "0x0B82d4e256eB980b2193CB4963275780Ca263b26", abi: ogV7},





    rainbowNFT:{address: "",abi:rainbowNFT},

    erc1155: {abi: erc1155},
};
export default CONTRACTS
