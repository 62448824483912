import styled from "styled-components";
export default  styled.div`
  .fire-list-box {
    .col:nth-child(2) {
      width: 60% !important;
    }

    .list-item,.list-header {
      padding-left: 20px;
    }
  }

`

