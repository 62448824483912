import {useConnect, connect} from "../../api/contracts";
import ConnectWallet from "../ConnectWallet/ConnectWallet";

import {useEffect, useState} from "react";
import {Button, notification, Select} from 'antd';
import passportIcon from "../../imgs/ethereum.png"
import right from "../../imgs/right.png"
import ArbIcon from "../../imgs/t5.png"
import polygon from "../../imgs/polygon.png"
import {useLocation, useNavigate} from "react-router-dom";
import NavList from "../NavList/MNavList";
import logo from "../../imgs/logo.png"
import develop from "../../env"
import user from "../../imgs/user.png";
import FireDAOHeaderStyle from "./FireDAOHeaderStyle";
import {getContractByName} from "../../api/connectContract";
import {viewMethod} from "../../utils/contractUtil";
import Logo from "../../imgs/logo_new.png"
import {Dropdown, Space} from 'antd';
import judgeStatus from "../../utils/judgeStatus";
import {Network} from "../../config/constants";

const items = [
    // {
    //     label: <div style={{
    //         width: '230px', marginLeft: '-3px', display: 'flex', height: '65px',
    //         background: '#241B1B',
    //         borderRadius: '20px', padding: '0px 5px', border: '1px solid rgba(234,234,234,0.1)'
    //     }}>
    //         <img style={{ width: '50px', height: '50px', marginTop: '8px' }} src={eth} /><span style={{ width: '50%', fontSize: '16px', lineHeight: '63px', marginLeft: '5px' }}>Arbitrum One</span><img style={{ width: '20px', height: '15px', marginTop: '23px', marginLeft: '25px' }} src={right} /></div>,
    //     key: '0',
    // },
    //
    {
        label: <div style={{
            width: '230px', marginLeft: '-3px', display: 'flex', height: '65px', background: '#241B1B',
            borderRadius: '20px', padding: '0px 5px', border: '1px solid rgba(234,234,234,0.1)'
        }}>
            <img style={{width: '50px', height: '50px', marginTop: '8px'}} src={ArbIcon}/><span
            style={{width: '50%', fontSize: '16px', lineHeight: '63px', marginLeft: '5px'}}>Arbitrum Goerli</span><img
            style={{width: '20px', height: '15px', marginTop: '23px', marginLeft: '25px', display: 'none'}}
            src={right}/></div>,
        key: '1',
    },

];
function isMobile(){
    if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        return true;
    }else{
        return false;
    }
}
const FireDAOHeader = () => {
    let {state, dispatch} = useConnect();
    const history = useNavigate();
    const [chain, setChain] = useState("Arbitrum Goerli")
    const goPage = (url) => {
        history(url);
    }


    useEffect(()=>{
        dispatch({type: "SET_ISMOBILE", payload: isMobile()})
    },[])
    const location = useLocation()

    const handleSoulViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("mintFireSoul", state.api,)
        if (!contractTemp) {
            return
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const handleUserViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("user", state.api,)
        if (!contractTemp) {
            return
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("mintFireSoul", state.api,)
        if (!contractTemp) {
            return
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const getUserFIDAndAddr = async () => {
        const UserFID = await handleSoulViewMethod("UserFID", [state.account])
        const userInfo = await handleUserViewMethod("userInfo", [state.account])
        dispatch({type: "SET_PID", payload: userInfo.PID})
        dispatch({type: "SET_FID", payload: UserFID})
    }
    const handleChange = () => {
        //  Network["0x66eed"]
        window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [

                Network["0xa4b1"]
            ],
        });


    }
    useEffect(async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        // getUserFIDAndAddr()

    }, [state.account, state.networkId]);
    return (
        <FireDAOHeaderStyle>
            {state.isShowNav && <div className="m-nav">
                <div className="m-nav-content">
                    <div className="close" onClick={() => {
                        dispatch({type: "SET_IsShowNav", payload: false})
                    }}>
                        <svg t="1675219743493" className="icon" viewBox="0 0 1024 1024" version="1.1"
                             xmlns="http://www.w3.org/2000/svg" p-id="2668" width="20" height="20">
                            <path
                                d="M925.468404 822.294069 622.19831 512.00614l303.311027-310.331931c34.682917-27.842115 38.299281-75.80243 8.121981-107.216907-30.135344-31.369452-82.733283-34.259268-117.408013-6.463202L512.000512 399.25724 207.776695 87.993077c-34.675754-27.796066-87.272669-24.90625-117.408013 6.463202-30.178323 31.414477-26.560936 79.375815 8.121981 107.216907l303.311027 310.331931L98.531596 822.294069c-34.724873 27.820626-38.341237 75.846432-8.117888 107.195418 30.135344 31.43699 82.72919 34.326806 117.408013 6.485715l304.178791-311.219137 304.177767 311.219137c34.678824 27.841092 87.271646 24.951275 117.408013-6.485715C963.808618 898.140501 960.146205 850.113671 925.468404 822.294069z"
                                fill="#ffffff" p-id="2669"></path>
                        </svg>
                    </div>
                    <NavList className="firenav" closeDialog={() => {
                        dispatch({type: "SET_IsShowNav", payload: false})
                    }}/>
                </div>
            </div>}

            <div className="firedao-header">

                <div className="currentTitle">
                    <div className="logo-box">
                        <a href="/" > <img className="logo" src={Logo} alt=""/></a>
                    </div>
                </div>
                <div className="nav-list">

                    <div className="nav-item">
                        <a href="https://docs.firedao.co/" target="_blank">
                            DOCS
                        </a>
                    </div>
                    <div className="nav-item">
                        <a href={develop.webside} target="_blank">
                            White Paper
                        </a>
                    </div>
                    <div className="nav-item">
                        <a href={develop.github} target="_blank">
                            Github
                        </a>
                    </div>
                </div>
                <Button onClick={(e) => {
                    handleChange()
                }} className="passport-icon" type="primary">
                    <div style={{display: 'flex'}}>
                        <img src={ArbIcon} alt=""/>
                        <span
                            style={{margin: '0px 10px', verticalAlign: 'middle', fontSize: '13px'}}>

                            {/*Arbitrum Goerli*/}
                            Arbitrum One
                        </span>
                        <div style={{
                            width: '6px',
                            height: '6px',
                            borderRadius: '50%',
                            backgroundColor: 'rgba(216, 216, 216, 1)',
                            margin: 'auto'
                        }}></div>
                    </div>
                </Button>

                <ConnectWallet/>

            </div>

        </FireDAOHeaderStyle>
    )

}
export default FireDAOHeader
