import {Routes, Route, Link, useNavigate, useLocation} from "react-router-dom";

import "animate.css";

import GlobalStyle from "./style/style";
import CommonStyle from "./style/commonStyle";
import AntdOverride from "./style/antdOverride";
import React, {useEffect} from "react";
import {ConnectProvider, useConnect} from "./api/contracts";
import FireDAOHeader from "./component/FireDAOHeader/FireDAOHeader";


import NFTView from "./view/FDTSquare/OGPoolV7/NFT/NFTView"
import NFTAdmin from "./view/FDTSquare/OGPoolV7/NFT/NFTAdmin"
import firebg from "./imgs/firebg.mp4"


import OGPoolV5 from "./view/FDTSquare/OGPoolV5/OGPool";
// import OGPoolV5Admin from "./view/FDTSquare/OGPoolV5/OGPoolAdmin";
// import OGV5UserAdmin from "./view/FDTSquare/OGPoolV5/OGUserAdmin";

import OGPoolV7 from "./view/FDTSquare/OGPoolAFIRE/OGPool";
import OGPoolV7Admin from "./view/FDTSquare/OGPoolAFIRE/OGPoolAdmin";
import OGV7UserAdmin from "./view/FDTSquare/OGPoolAFIRE/OGUserAdmin";


import OGPoolAFIRE from "./view/FDTSquare/OGPoolAFIRE/OGPool";
import OGPoolV5Admin from "./view/FDTSquare/OGPoolAFIRE/OGPoolAdmin";
import OGV5UserAdmin from "./view/FDTSquare/OGPoolAFIRE/OGUserAdmin";


function App() {

    return (

        <ConnectProvider>
            <CommonStyle/>
            <GlobalStyle/>
            <AntdOverride/>

            <div className="content">
                <video webkit-playsinline="true"  playsInline={true}  x5-video-orientation="portraint"   x5-playsinline="true" className="firebg" width="100%" autoPlay="autoplay" loop="loop" muted="muted">
                    <source src={firebg} type="video/mp4"/>
                </video>
                <FireDAOHeader/>
                <div className="App" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop:"6em"
                }}>

                    <div className="flex-container" style={{
                        width:"100%",
                        flexGrow:"1"
                    }}>
                        <Routes>
                            <Route path="/" element={<OGPoolAFIRE/>}/>
                            <Route path="/OGPoolV5Admin" element={<OGPoolV5Admin/>}/>
                            <Route path="/OGV5UserAdmin" element={<OGV5UserAdmin/>}/>
                            <Route path="/OGUserAdmin" element={<OGV5UserAdmin/>}/>

                            <Route path="/OGPoolV7" element={<OGPoolV7/>}/>
                            <Route path="/OGPoolV7Admin" element={<OGPoolV7Admin/>}/>
                            <Route path="/OGV7UserAdmin" element={<OGV7UserAdmin/>}/>

                            <Route path="/NFTView" element={<NFTView/>}/>
                            <Route path="/NFTAdmin" element={<NFTAdmin/>}/>


                        </Routes>
                    </div>
                    <div className='twindow'>

                    </div>
                </div>
                {/*<FireDAOFooter/>*/}
            </div>
        </ConnectProvider>

    )
}

export default App;
